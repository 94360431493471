export const worksitesColumns = [
  'ID',
  'Date de création du dossier',
  'Agence affiliée',
  "Raison sociale de l'installateur",
  'Statut du dossier',
  'Date de dernier état',
  'Montant MPR',
  'Total KWh cumac',
  'Montant CEE',
  'Montant CEE versé',
  "Type d'opération",
  'Tranche de revenu',
  'Eligibilité Coup de pouce',
  "Type d'habitation",
  'Code postal des travaux',
];

export const worksitesColumnsKeys = [
  'id_dossier',
  'date_creation_dossier',
  'agence',
  'raison_sociale',
  'statut_dossier',
  'date_derniere_modification_dossier',
  'montant_mpr',
  'total_kwhc',
  'montant_cee',
  'montant_cee_verse',
  'libelle_operation',
  'tranche_revenu',
  'coup_de_pouce',
  'type_habitation',
  'code_postal',
];

export const worksitesColumnsWithKeys = worksitesColumns.map(
  (column, index) => ({
    label: column,
    key: worksitesColumnsKeys[index],
  })
);
