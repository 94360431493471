/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { urlPartnerIdentities, urlPartnerList } from '@api/apiUrls';
import { Dispatch, SetStateAction } from 'react';

export const getPartnerIdentities = async (
  partner: string | undefined,
  hash: string | undefined,
  agence: string | undefined,
  setError: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  let payload = {};
  if (agence) {
    payload = {
      partenaire: partner,
      agence,
      hash,
    };
  } else {
    payload = {
      partenaire: partner,
      hash,
    };
  }
  try {
    const response = await axiosClient.post(urlPartnerIdentities, payload);
    return response.data;
  } catch (error) {
    setError(error.response.data.error);
    console.log(error.response);
    setIsLoading(false);
  }
};

export const getPartnerList = async (
  partner: string | undefined,
  hash: string | undefined,
  agence: string | undefined,
  numberPage: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  let payload = {};
  if (agence) {
    payload = {
      partenaire: partner,
      agence,
      hash,
      per_page: 25,
      page: numberPage,
    };
  } else {
    payload = {
      partenaire: partner,
      hash,
      per_page: 25,
      page: numberPage,
    };
  }
  try {
    const response = await axiosClient.post(urlPartnerList, payload);
    return response.data;
  } catch (error) {
    console.log(error.response);
    setIsLoading(false);
  }
};
