import { ChevronSelectIcon } from '@assets/svgComponents';
import { GlobalContext } from '@context/globalContext';
import React, { useContext, useState } from 'react';

interface ISelectPartnerProps {
  choicesSelectPartner: Record<string, string>;
}

function SelectPartner({ choicesSelectPartner }: ISelectPartnerProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { darkMode } = useContext(GlobalContext);

  return (
    <div
      className={`${
        isDropdownOpen
          ? 'border dark:border-[#EEEEEE] text-xs border-[#000000] border-[.5px]'
          : ''
      } w-4/12 rounded-[8px] ml-[1.5rem]`}
    >
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        type="button"
        className={`flex items-center text-xs justify-between w-full px-[1rem] rounded-[8px] ${
          isDropdownOpen
            ? ''
            : 'border border-[#121212] border-[.5px] dark:border-[#EEEEEE]'
        } py-[.5rem]`}
      >
        <p>Selectionner</p>
        <ChevronSelectIcon
          className={isDropdownOpen ? 'transform rotate-180' : ''}
          height="1.5rem"
          width="1.5rem"
          fill={darkMode ? '#FFFFFF' : '#000000'}
        />
      </button>
      {isDropdownOpen && (
        <div className="flex flex-col pb-[.5rem] max-h-[30vh] overflow-y-scroll">
          {Object.entries(choicesSelectPartner).map(([key, value]) => (
            <a
              className="py-[.25rem] px-[1rem]  hover:bg-gray-200 hover:scale-y-110"
              href={`${process.env.REACT_APP_URL}/${value}`}
              target="_blank"
              rel="noreferrer"
              key={key}
            >
              {key}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export { SelectPartner };
