import { Dashboard } from '@pages/Dashboard';
import { Navigate, Route, Routes } from 'react-router-dom';

function Router(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/:partner/:hash" element={<Dashboard />} />
      <Route path="/:partner/:agence/:hash" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Router;
