import { IUrlPartnerData } from 'types/globalTypes';
import { createContext } from 'react';

interface IProps {
  urlPartnerData: IUrlPartnerData;
  setUrlPartnerData: (urlComponents: IUrlPartnerData) => void;
  darkMode: boolean;
}

export const GlobalContext = createContext({} as IProps);
