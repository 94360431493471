/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { IOnePartnerInList } from '@models/partners/utils/types';
import { getPartnerList } from '@models/partners/apiRequests/partnersRequests';
import { PartnerContext } from '@models/partners/context/PartnerContext';
import { useParams } from 'react-router-dom';
import { Loader } from '@components/Loader';
import { numFormatSpace } from '@utils/functions';
import {
  IColumn,
  IExpandableContent,
  IRow,
  ListTable,
} from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { worksiteTableDef } from '@models/worksites/utils/worksiteTableDef';
import { getWorksiteList } from '@models/worksites/apiRequests/worksitesRequests';

function PartnerList() {
  const { partner, hash, agence } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listIsLoaded, setListIsLoaded] = useState<boolean>(false);
  const [isEndList, setIsEndList] = useState<boolean>(false);
  const [divScrollable, setDivScrollable] = useState<HTMLElement | null>(null);
  const [numberPage, setNumberPage] = useState<number>(1);

  const { setPartnerList, partnerList, setTotalList, totalList } =
    useContext(PartnerContext);

  const expandedColumns = useMemo(() => {
    return worksiteTableDef;
  }, []);

  const getListPartnerData = async () => {
    setIsLoading(true);
    const response = await getPartnerList(
      partner,
      hash,
      agence,
      numberPage,
      setIsLoading
    );
    setPartnerList(response.summary);
    setTotalList(response.total);
    setIsLoading(false);
  };

  useEffect(() => {
    setDivScrollable(document.getElementById('scrollDiv'));
    getListPartnerData();
  }, []);

  const infiniteScroll = async () => {
    if (
      partnerList !== null &&
      partnerList.length > 14 &&
      divScrollable &&
      divScrollable?.offsetHeight + divScrollable?.scrollTop >=
        divScrollable?.scrollHeight &&
      !isEndList
    ) {
      if (!listIsLoaded && !isEndList) {
        setNumberPage(numberPage + 1);
        setListIsLoaded(true);
        const response = await getPartnerList(
          partner,
          hash,
          agence,
          numberPage + 1,
          setIsLoading
        );
        setListIsLoaded(false);
        const newListPartner = partnerList.concat(response.summary);
        setPartnerList(newListPartner);
        if (response.next_page_url === null) {
          setIsEndList(true);
          toast('Fin de la liste.');
        }
      }
    }
  };

  const columnHelper = createColumnHelper<IOnePartnerInList>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('raison_sociale', {
        header: () => 'Raison sociale',
      }),
      columnHelper.accessor('contact', {
        header: () => 'Contact',
      }),
      columnHelper.accessor('nb_traitement', {
        header: () => 'Dossiers en traitement',
      }),
      columnHelper.accessor('nb_valides', {
        header: () => 'Dossiers validés',
      }),
    ];
  }, [partnerList]);

  return (
    <div className="pt-[1rem]">
      {isLoading ? (
        <Loader isBig />
      ) : (
        <div>
          <div className="w-full p-[1.5rem] dark:bg-[#272727] bg-[#F7F7FB] text-xs rounded-b-[8px] flex justify-between items-center">
            <div>
              <p className="font-medium">Montant CEE dossiers validés :</p>
              <p> {`${numFormatSpace(totalList.cee_valides)} €`}</p>
            </div>
            <div>
              <p className="font-medium">
                Volume CEE dossiers validés (kWhc Kumac) :{' '}
              </p>
              <p>{`${totalList.kwhc_valides} kWhc Kumac`}</p>
            </div>
            <div>
              <p className="font-medium">Montant MPR dossiers payé :</p>
              <p>{`${numFormatSpace(totalList.mpr_payes)} €`}</p>
            </div>
            <div>
              <p className="font-medium">Installateurs actifs :</p>
              <p>{`${totalList.nb_pros}`}</p>
            </div>
          </div>
          <ListTable
            infiniteScroll={infiniteScroll}
            data={partnerList ?? []}
            columns={columns as IColumn[]}
            expandableRows
            fetchExpandedRowContent={async (row) => {
              const installateur = row.original as IOnePartnerInList;

              const response = await getWorksiteList(
                partner,
                hash,
                agence,
                1,
                setIsLoading,
                installateur.id,
                false
              );

              return {
                columns: expandedColumns as IColumn[],
                data: response.summary as IRow[],
              } as IExpandableContent;
            }}
          />
        </div>
      )}
    </div>
  );
}

export { PartnerList };
