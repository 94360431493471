/* eslint-disable consistent-return */
import { axiosClient } from '@api/apiClient';
import { urlPartnerWorksite } from '@api/apiUrls';
import { Dispatch, SetStateAction } from 'react';

export const getWorksiteList = async (
  partner: string | undefined,
  hash: string | undefined,
  agence: string | undefined,
  numberPage: number,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  installateur: string | undefined = undefined,
  paginate = true
) => {
  let payload = {};
  if (agence) {
    payload = {
      partenaire: partner,
      agence,
      hash,
      per_page: 25,
      page: numberPage,
      installateur,
      paginate,
    };
  } else {
    payload = {
      partenaire: partner,
      hash,
      per_page: 25,
      page: numberPage,
      paginate,
    };
  }
  try {
    const response = await axiosClient.post(urlPartnerWorksite, payload);
    return response.data;
  } catch (error) {
    console.log(error.response);
    setIsLoading(false);
  }
};
