import { createColumnHelper } from '@tanstack/react-table';
import { IOneWorksiteInList } from '@models/worksites/utils/types';
import { worksitesColumnsWithKeys } from '@models/worksites/utils/worksitesUtils';

const columnHelper = createColumnHelper<IOneWorksiteInList>();

export const worksiteTableDef = worksitesColumnsWithKeys.map((column) =>
  columnHelper.accessor(column.key as keyof IOneWorksiteInList, {
    header: () => column.label,
  })
);
