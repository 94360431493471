import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BACK_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
axiosClient.interceptors.response.use(
  (response) => {
    // Si la réponse contient une exception, on affiche un message d'erreur dans un toast
    if (response.data.exception) {
      console.log(response.data.exception);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      // La requête a été faite mais le serveur a répondu avec une erreur
      const { data, status } = error.response;
      console.log('Request made but the server responded with an error :', {
        data,
        status,
      });
    } else if (error.request) {
      // La requête a été faite mais aucune réponse n'a été reçue du serveur
      console.log(
        'Request made but no response is received from the server :',
        error.request
      );
    } else {
      // Une erreur s'est produite lors du setup de la requête
      console.log(
        'Error occured while setting up the request :',
        error.message
      );
    }
    return Promise.reject(error);
  }
);

export { axiosClient };
