import { createContext, Dispatch, SetStateAction } from 'react';
import { IOnePartnerInList, ITotalListPartner } from '../utils/types';

interface IProps {
  partnerList: IOnePartnerInList[] | null;
  setPartnerList: Dispatch<SetStateAction<IOnePartnerInList[] | null>>;
  totalList: ITotalListPartner;
  setTotalList: Dispatch<SetStateAction<ITotalListPartner>>;
}

export const PartnerContext = createContext({} as IProps);
