import React, { useState } from 'react';
import {
  Column,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { v4 } from 'uuid';
import { Loader } from '@components/Loader';

export type IRow = Record<string, unknown>;
export type IColumn = Column<any>;

export interface IExpandableContent {
  columns: IColumn[];
  data: IRow[];
}

interface IListProps {
  data: IRow[];
  columns: IColumn[];
  infiniteScroll?: () => void;
  expandableRows?: boolean;
  fetchExpandedRowContent?: (row: IRow) => Promise<IExpandableContent>;
  child?: boolean;
}

function ListTable({
  data,
  columns,
  infiniteScroll,
  expandableRows,
  fetchExpandedRowContent,
  child,
}: IListProps) {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [expandedContent, setExpandedContent] = useState<IExpandableContent[]>(
    []
  );
  const [loadingExpandedRows, setLoadingExpandedRows] = useState<string[]>([]);

  const handleRowClick = async (index: number, row: any) => {
    if (!fetchExpandedRowContent) return;
    if (expandedRows.includes(row.id)) {
      setExpandedRows((prev) => prev.filter((id) => id !== row.id));
    } else {
      setExpandedRows((prev) => prev.concat(row.id));
      if (expandedContent[index]) return;
      setLoadingExpandedRows((prev) => prev.concat(row.id));
      const response = await fetchExpandedRowContent(row);
      const newExpandedContent = [...expandedContent];
      newExpandedContent[index] = response;
      setExpandedContent(newExpandedContent);
      setLoadingExpandedRows((prev) => prev.filter((id) => id !== row.id));
    }
  };

  const addClass = child ? 'h-fit' : 'h-[75vh]';
  const childColor = child ? 'bg-[#F7F7F7]' : 'bg-[#ffffff]';

  return (
    <div className={`dark:bg-[#1E1E1E] ${childColor}`}>
      <div>
        <div className="sm:block">
          {table.getHeaderGroups().map((headerGroup) => (
            <div
              key={headerGroup.id}
              className="block sm:grid gap-x-4 p-[28px] grid-flow-col auto-cols-fr"
            >
              {headerGroup.headers.map((header) => (
                <div
                  key={v4()}
                  className="items-center justify-start text-[.65rem] font-bold"
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          id="scrollDiv"
          onScroll={infiniteScroll}
          className={`overflow-scroll max-h-[75vh] rounded-[8px] rounded-t-[0px] ${addClass}`}
        >
          {table.getRowModel().rows.map((row, index) => {
            return (
              <React.Fragment key={row.id}>
                <div
                  onClick={() =>
                    expandableRows ? handleRowClick(index, row) : undefined
                  }
                  onKeyDown={() =>
                    expandableRows ? handleRowClick(index, row) : undefined
                  }
                  role="presentation"
                  className={`grid gap-4 p-[28px] bg-white ${
                    expandableRows ? 'cursor-pointer' : ''
                  } grid-flow-col auto-cols-fr break-all`}
                  style={{
                    borderBottom: '1px solid #EEEEEE',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <div
                      key={cell.id}
                      className="font-normal justify-start overflow-hidden text-[.6rem]"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  ))}
                </div>
                {loadingExpandedRows.includes(row.id) && <Loader />}
                {expandedRows.includes(row.id) &&
                  expandedContent &&
                  expandedContent[index] && (
                    <ListTable
                      data={expandedContent[index].data}
                      columns={expandedContent[index].columns}
                      child
                    />
                  )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

ListTable.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  infiniteScroll: () => {},
  expandableRows: false,
  fetchExpandedRowContent: null,
  child: false,
};

export { ListTable };
