import { GlobalContext } from '@context/globalContext';
import { IUrlPartnerData } from 'types/globalTypes';
import { useMemo, useState } from 'react';
import { Header } from '@components/Header';
import { ToastContainer } from 'react-toastify';

interface ILayoutProps {
  children: JSX.Element;
}

function Layout({ children }: ILayoutProps) {
  const [darkMode, setDarkMode] = useState(false);

  const [urlPartnerData, setUrlPartnerData] = useState<IUrlPartnerData>({
    partenaire: undefined,
    agence: undefined,
    hash: undefined,
  });

  const globalContextValues = useMemo(
    () => ({
      urlPartnerData,
      setUrlPartnerData,
      darkMode,
    }),
    [urlPartnerData, darkMode]
  );

  return (
    <GlobalContext.Provider value={globalContextValues}>
      <ToastContainer />
      <div className={`${darkMode && 'dark'} `}>
        <div className="dark:bg-[#202020] dark:text-[#ffffff] text-[#191919] min-h-screen min-w-screen">
          <Header setDarkToggle={setDarkMode} darkToggle={darkMode} />
          {children}
        </div>
      </div>
    </GlobalContext.Provider>
  );
}

export { Layout };
