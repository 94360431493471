import { useContext, useEffect, useMemo, useState } from 'react';
import { PartnerContext } from '@models/partners/context/PartnerContext';
import { toast } from 'react-toastify';
import { numFormatSpace } from '@utils/functions';
import { Loader } from '@components/Loader';
import { IColumn, ListTable } from '@components/ListTable';
import { useParams } from 'react-router-dom';
import { worksiteTableDef } from '@models/worksites/utils/worksiteTableDef';
import { IOneWorksiteInList } from '@models/worksites/utils/types';
import { getWorksiteList } from '@models/worksites/apiRequests/worksitesRequests';

function WorksiteList() {
  const { partner, hash, agence } = useParams();
  const [data, setData] = useState<IOneWorksiteInList[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listIsLoaded, setListIsLoaded] = useState<boolean>(false);
  const [isEndList, setIsEndList] = useState<boolean>(false);
  const [divScrollable, setDivScrollable] = useState<HTMLElement | null>(null);
  const [numberPage, setNumberPage] = useState<number>(1);

  const { setTotalList, totalList } = useContext(PartnerContext);

  const columns = useMemo(() => {
    return worksiteTableDef;
  }, [data]);

  const getPartnerWorksiteData = async () => {
    setIsLoading(true);
    const response = await getWorksiteList(
      partner,
      hash,
      agence,
      numberPage,
      setIsLoading
    );
    setData(response.summary);
    setTotalList(response.total);
    setIsLoading(false);
  };

  useEffect(() => {
    setDivScrollable(document.getElementById('scrollDiv'));
    getPartnerWorksiteData();
  }, []);

  const infiniteScroll = async () => {
    if (
      data !== null &&
      data.length > 16 &&
      divScrollable &&
      // eslint-disable-next-line no-unsafe-optional-chaining
      divScrollable?.offsetHeight + divScrollable?.scrollTop >=
        divScrollable?.scrollHeight &&
      !isEndList
    ) {
      if (!listIsLoaded && !isEndList) {
        setNumberPage(numberPage + 1);
        setListIsLoaded(true);
        const response: any = await getWorksiteList(
          partner,
          hash,
          agence,
          numberPage,
          setIsLoading
        );
        setListIsLoaded(false);
        setData(data.concat(response.summary));
        if (response.next_page_url === null) {
          setIsEndList(true);
          toast('Fin de la liste.');
        }
      }
    }
  };

  return (
    <div className="pt-[1rem]">
      {isLoading ? (
        <Loader isBig />
      ) : (
        <div>
          <div className="w-full p-[1.5rem] dark:bg-[#272727] bg-[#F7F7FB] text-xs rounded-b-[8px] flex justify-between items-center">
            <div>
              <p className="font-medium">Montant CEE dossiers validés :</p>
              <p> {`${numFormatSpace(totalList.cee_valides)} €`}</p>
            </div>
            <div>
              <p className="font-medium">
                Volume CEE dossiers validés (kWhc Kumac) :{' '}
              </p>
              <p>{`${totalList.kwhc_valides} kWhc Kumac`}</p>
            </div>
            <div>
              <p className="font-medium">Montant MPR dossiers payé :</p>
              <p>{`${numFormatSpace(totalList.mpr_payes)} €`}</p>
            </div>
            <div>
              <p className="font-medium">Installateurs actifs :</p>
              <p>{`${totalList.nb_pros}`}</p>
            </div>
          </div>
          <ListTable
            data={data}
            columns={columns as IColumn[]}
            infiniteScroll={infiniteScroll}
          />
        </div>
      )}
    </div>
  );
}

export { WorksiteList };
