/* eslint-disable jsx-a11y/label-has-associated-control */
import { GreenSolutionsLogo, Logo } from '@assets/svgComponents';
import { Dispatch, SetStateAction } from 'react';
import { ExportTable } from '@components/ExportTable';

interface IHeaderProps {
  setDarkToggle: Dispatch<SetStateAction<boolean>>;
  darkToggle: boolean;
}

function Header({ setDarkToggle, darkToggle }: IHeaderProps) {
  return (
    <div className="justify-between flex items-center pl-[1.5rem] pb-[1.5rem]">
      <div className="flex justify-between gap-2">
        <Logo
          height="5rem"
          width="8rem"
          fill={darkToggle ? '#ffffff' : '#000000'}
        />
        <GreenSolutionsLogo height="5rem" width="8rem" />
      </div>
      <div>
        <ExportTable />
        <input
          onClick={() => setDarkToggle(!darkToggle)}
          type="checkbox"
          className="input_dark_mode"
          id="dark-mode"
        />
        <label
          htmlFor="dark-mode"
          className="label_dark_mode absolute right-[1.5rem] top-[1.5rem] w-[4rem] h-[1.75rem] bg-[#1730bf] rounded-[1.25rem] cursor-pointer"
        >
          <div className="circle absolute bg-[#FFFFFF] rounded-[50%] top-[.25rem] left-[.25rem] w-[1.25rem] h-[1.25rem]" />
        </label>
      </div>
    </div>
  );
}

export { Header };
