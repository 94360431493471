/* eslint-disable no-nested-ternary */
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getPartnerIdentities } from '@models/partners/apiRequests/partnersRequests';
import { SelectPartner } from '@models/partners/components/SelectPartner';
import { Loader } from '@components/Loader';
import { PartnerContext } from '@models/partners/context/PartnerContext';
import {
  IOnePartnerInList,
  ITotalListPartner,
} from '@models/partners/utils/types';
import { GlobalContext } from '@context/globalContext';
import { PartnerList } from '@models/partners/components/list/PartnerList';
import { WorksiteList } from '@models/worksites/components/list/WorksiteList';

function Dashboard() {
  const { setUrlPartnerData } = useContext(GlobalContext);

  const { partner, hash, agence } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [partnerList, setPartnerList] = useState<IOnePartnerInList[] | null>(
    null
  );
  const [totalList, setTotalList] = useState<ITotalListPartner>({
    cee_valides: 0,
    kwhc_valides: 0,
    mpr_payes: 0,
    nb_traitement: 0,
    nb_valides: 0,
    nb_pros: 0,
    nb_pros_cee: 0,
    nb_pros_signature_cee: 0,
  });
  const [error, setError] = useState('');

  const [choicesSelectPartner, setChoicesSelectPartner] = useState<
    Record<string, string>
  >({ drapo: '' });

  const getChoicesSelectPartner = async () => {
    setIsLoading(true);
    const choices = await getPartnerIdentities(
      partner,
      hash,
      agence,
      setError,
      setIsLoading
    );
    setChoicesSelectPartner(choices.choices);
    setIsLoading(false);
  };

  const partnerContextValues = useMemo(
    () => ({
      partnerList,
      setPartnerList,
      totalList,
      setTotalList,
    }),
    [partnerList, totalList]
  );

  useEffect(() => {
    if (hash !== undefined) {
      getChoicesSelectPartner();
    }
  }, [hash]);

  useEffect(() => {
    setUrlPartnerData({
      partenaire: partner,
      agence,
      hash,
    });
  }, [partner, agence, hash]);

  return (
    <PartnerContext.Provider value={partnerContextValues}>
      {isLoading ? (
        <div className="fixed top-[50%] left-[50%] transform-[translate(-50%, -50%)]">
          <Loader isBig />
        </div>
      ) : error !== '' ? (
        <div className="mx-[1.5rem] bg-red-200 rounded-[8px] p-[.5rem] text-red-500">
          <p>
            Cette page est actuellement indisponible. Merci de contacter Drapo.
          </p>
        </div>
      ) : (
        <div className="px-[1.5rem] ">
          {hash !== undefined ? (
            <div className="dark:bg-[#272727] bg-[#F7F7FB] pt-[1rem] rounded-[9px] border dark:border-[#272727]">
              {Object.entries(choicesSelectPartner).length > 1 && (
                <SelectPartner choicesSelectPartner={choicesSelectPartner} />
              )}
              {agence ? <PartnerList /> : <WorksiteList />}
            </div>
          ) : (
            <p>Merci de contacter Drapo pour accéder à votre dashboard.</p>
          )}
        </div>
      )}
    </PartnerContext.Provider>
  );
}

export { Dashboard };
