export const roundFormat = (value: number | string) => {
  const valueFormat = typeof value === 'string' ? parseFloat(value) : value;
  return (Math.round(valueFormat * 100) / 100).toFixed(2).toLocaleString();
};

export const numFormatSpace = (
  a: string | number,
  withoutDecimal?: boolean
) => {
  let str = roundFormat(a);
  if (withoutDecimal) {
    str = Math.round(parseFloat(str)).toString();
  }
  const decimal = str !== null ? str.split('.', str.length - 1) : [''];
  if (decimal[1] === '00') {
    return decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
  }
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
};

export const todayDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const dayFormat = day < 10 ? `0${day}` : day;
  const monthFormat = month < 10 ? `0${month}` : month;

  return `${year}_${monthFormat}_${dayFormat}`;
};
