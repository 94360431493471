import { useContext, useRef, useState } from 'react';
import { Loader } from '@components/Loader';
import { CSVLink } from 'react-csv';
import { todayDate } from '@utils/functions';
import { DownloadIcon } from '@assets/svgComponents';
import { GlobalContext } from '@context/globalContext';
import { IOneWorksiteInList } from '@models/worksites/utils/types';
import { worksitesColumnsWithKeys } from '@models/worksites/utils/worksitesUtils';
import { getWorksiteList } from '@models/worksites/apiRequests/worksitesRequests';

function ExportTable() {
  const { urlPartnerData } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [totalList, setTotalList] = useState<IOneWorksiteInList[]>([]);
  const csvLinkEl = useRef<any | null>(null);

  const areUrlPartnerDataLoaded = () => {
    return (
      urlPartnerData.partenaire || urlPartnerData.agence || urlPartnerData.hash
    );
  };

  const shouldShowDownloadIcon = () => {
    return (
      urlPartnerData.partenaire && urlPartnerData.hash && !urlPartnerData.agence
    );
  };

  const getData = async () => {
    setIsLoading(true);
    if (!areUrlPartnerDataLoaded()) {
      setIsLoading(false);
      return;
    }
    const response = await getWorksiteList(
      urlPartnerData.partenaire,
      urlPartnerData.hash,
      urlPartnerData.agence,
      1,
      setIsLoading,
      undefined,
      false
    );

    const reformatedSummary = response.summary.map(
      (data: IOneWorksiteInList) => {
        return {
          ...data,
        };
      }
    );

    setTotalList((prev) => [...prev, ...reformatedSummary]);
    setIsLoading(false);
  };

  const downloadReport = async () => {
    await getData();
    csvLinkEl.current.link.click();
  };

  return (
    <div>
      {shouldShowDownloadIcon() && isLoading ? (
        <div className="absolute right-[7rem]">
          <Loader isBig={false} />
        </div>
      ) : (
        shouldShowDownloadIcon() && (
          <>
            <button
              type="button"
              onClick={downloadReport}
              className="absolute right-[7rem]"
            >
              <DownloadIcon />
            </button>
            <CSVLink
              data={totalList}
              filename={`export_${todayDate()}`}
              headers={worksitesColumnsWithKeys}
              ref={csvLinkEl}
            />
          </>
        )
      )}
    </div>
  );
}

export { ExportTable };
